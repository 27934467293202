// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#btn:hover {
    background-color: #190b14;
    opacity: 0.9;
  }
  
  .login-powered {
    position: relative;
    margin: 5vh;
    left: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/SelectRecipient/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB","sourcesContent":["#btn:hover {\n    background-color: #190b14;\n    opacity: 0.9;\n  }\n  \n  .login-powered {\n    position: relative;\n    margin: 5vh;\n    left: 10px;\n    bottom: 10px;\n    display: flex;\n    align-items: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
