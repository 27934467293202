import { useMemo } from "react";
import React, { useEffect, useState } from "react";
import { Typography } from "../Typography";
import { Loader } from "../Loader";

export const RE_DIGIT = new RegExp(/^\d+$/);

export type Props = {
  value: string;
  valueLength: number;
  error?: boolean;
  success?: boolean;
  label: string;
  helperText: string;
  onChange: (value: string) => void;
  loading?: boolean;
  width?: string;
  maxWidth?: string;
  cooldown: boolean;
  innerWidth?: string;
  isFocus?: boolean;
};

export const OtpInput = ({
  value,
  valueLength,
  error,
  success,
  label,
  helperText,
  onChange,
  loading,
  width,
  maxWidth,
  cooldown,
  innerWidth,
  isFocus,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling =
        target.nextElementSibling as HTMLInputElement | null;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  useEffect(() => {
    if (isFocus) document.querySelectorAll('input')[2].focus()
  }, [isFocus]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { key } = e;
    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);
    if (e.key !== "Backspace" || target.value !== "") {
      return;
    }
    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  return (
    <div style={{ position: "relative", marginTop: 40 }}>
      <div style={{ display: "flex", opacity: loading ? "40%" : "100%" }}>
        <Typography variant={"label"} size={"medium"} color={"#72335D"}>
          {label}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          width: width,
          maxWidth: maxWidth,
          justifyContent: "space-between",
          // columnGap: "1.85vh",
          marginBottom: "8px",
          marginTop: "8px",
          opacity: loading ? "40%" : "100%",
        }}
      >
        
        {valueItems.map((digit, idx) => (
          <input
            key={idx}
            id= {'inputs'}
            type="text"
            inputMode="numeric"
            style={{
              // width: "36px",
              width: innerWidth ? innerWidth : "36px",
              height: "36px",
              textAlign: "center",
              fontFamily: "Space Grotesk",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "16px",
              color: "#190b14",
              border: error
                ? "1px solid red"
                : success
                ? "1px solid green"
                : "1px solid transparent",
              background: error ? "#FFEFED" : success ? "#E6F2ED" : "#F6F6F6",
            }}
            // autoFocus={focus}
            pattern="\d{1}"
            maxLength={valueLength}
            value={digit}
            disabled={loading || cooldown}
            onFocus={inputOnFocus}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
          />
        ))}
        {loading && (
          <div
            style={{
              padding: 0,
              position: "absolute",
              top: "45%",
              left: "45%",
            }}
          >
            <Loader size="extra-small" />
          </div>
        )}
      </div>
      {!loading && !success && (
        <Typography
          variant={"paragraph"}
          size={"small"}
          color={error ? "#E11900" : "#6B6B6B"}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};
