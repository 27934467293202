// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;
  align-items: center;
  justify-self: center;
}

.navbar > *,
.navbar > * > * {
  flex: 100% 1;
  justify-content: center;
}

.navbar > * {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;;EAEE,YAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px 0 0;\n  align-items: center;\n  justify-self: center;\n}\n\n.navbar > *,\n.navbar > * > * {\n  flex: 100%;\n  justify-content: center;\n}\n\n.navbar > * {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
