import { Fragment, useEffect, useState } from "react";
import { TextField, Box, Button, CircularProgress } from "@mui/material";

// @ts-ignore
import "./style.css";

import mewtIcon from "./../../assets/mewt.png";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { OtpInput } from "../../components/OTPContainer";
import axios from "axios";

export const AUTH_TOKEN =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjI0OTg0MTA2LCJqdGkiOiJjNGUzMzkwZmUzZGE0N2VmYTA1NTBhODhlZDFhYTlhZiIsInVzZXJfaWQiOjF9.nwfqpBBJJoYapcqFyQfxuSEayI36mnvNj2Y15Liy_0Q";

// export const API_TOKEN = '7B5zIqmRGXmrJTFmKa99vcit';
export const API_TOKEN = "S1wNkFBuj58vw7EZoRIxo48PwKfsv7Np8Z6hoOfy"; // For internal testing

export const Login = () => {
  const navigate = useNavigate();
  const [sendLoading, setSendLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [focusOTP, setFocusOTP] = useState(false);
  const [otpStatus, setOTPStatus] = useState<{
    loading: boolean;
    success: boolean;
    error: boolean;
  }>({ loading: false, success: false, error: false });
  const handleChangePhone = (event: any) => {
    const corrected = event.target.value.replace(/([^+0-9]+)/gi, "");
    if (
      !(corrected?.length > 0 && corrected == 0) &&
      corrected >= 0 &&
      corrected?.length <= 10
    ) {
      setPhone(corrected);
    }
  };

  const handleSendOTP = () => {
    setSendLoading(true);
    const headers = {
      Authorization: AUTH_TOKEN,
      "Content-Type": "application/json",
      "x-api-key": API_TOKEN,
      "session-id": "RANDOM_ID",
      "merchant-id": "RANDOM_ID",
    };
    axios
      .post(
        "https://dev.api.mewt.in/backend/v1/authentication/otp-authentication/generate-token/",
        {
          phone: "+91" + phone,
          source: "ec_portal",
        },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setSendLoading(false)
          setFocusOTP(true);
        } else {
          setOTP("");
          setOTPStatus((prev) => ({
            ...prev,
            loading: false,
            error: true,
            success: false,
          }));
        }
      })
      .catch((err) => {});
  };

  const handleOTPChange = (value: any) => {
    setOTP(value)
    const headers = {
      Authorization: AUTH_TOKEN,
      "Content-Type": "application/json",
      "x-api-key": API_TOKEN,
      "session-id": "RANDOM_ID",
      "merchant-id": "RANDOM_ID",
    };
    
    if (value.length ===4) {
      setSendLoading(true);
      navigate("/delete-user");

    axios
      .post(
        "https://dev.api.mewt.in/backend/v1/authentication/otp-authentication/validate-token/",
        {
          phone: "+91" + phone,
          token: value,
          source: "ec_portal",
        }, 
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          setSendLoading(false)
          navigate("/pay-amount");
        } else {
          navigate("/pay-amount");
          setOTP("");
          setOTPStatus((prev) => ({
            ...prev,
            loading: false,
            error: true,
            success: false,
          }));
        }
      })
      .catch((err) => {});
    }
  };

  const handleSubmit = () => {
    navigate("/pay-amount");
  };

  return (
    <Box sx={{ margin: "20px", padding: "20px" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Fragment>Login to delete data</Fragment>
          </div>
          {/* <div className="login-powered">
            <img src={mewtIcon} alt="Mewt" style={{ height: "30px" }} /> powered
            by mewt{" "}
          </div> */}
        </Box>
        <Input
          value={phone}
          onChange={handleChangePhone}
          type="tel"
          placeholder="enter phone number"
          width={"100%"}
          maxWidth={"328px"}
          keyboardType="tel"
          
        />

        <OtpInput
          onChange={handleOTPChange}
          label=""
          helperText={
            otpStatus.error && otp.length === 0
              ? "the code you entered is incorrect. try again."
              : otpStatus.error && otp.length > 0
              ? "the code you entered is incorrect."
              : "enter OTP"
          }
          value={otp}
          valueLength={4}
          loading={otpStatus.loading}
          success={otpStatus.success}
          error={otpStatus.error}
          cooldown={false}
          width="50vw"
          isFocus={focusOTP}
          
        />

<Button
          id="btn"
          type="submit"
          data-testid="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 15, marginTop: 15, fontFamily:'Space Grotesk', backgroundColor: "#190b14", height: "4em" }}
          onClick={() => handleSendOTP()}
        >
          {sendLoading ? <CircularProgress /> : "Send OTP"}
        </Button>
      </Box>
      {/* </Box> */}
    </Box>
  );
};
