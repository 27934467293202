import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import './styles.css';
import addRecipient from './../../assets/addrecipient.png';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import { Input } from '../../components/Input';

import { useState } from 'react';

export const DeleteUser = () => {

  const [buttonPressed, setButtonPressed] = useState(false);

  const handleButtonClick = () => {
    setButtonPressed(true);
  };

  const tableData = [
    { number: 1, type: 'Demographics', description: 'Users Location and Language info will be deleted' },
    { number: 2, type: 'Account Info.', description: 'Bank Details, Saved Images and Store Details will be deleted' },
    { number: 3, type: 'Transaction Info.', description: 'Information about the transactions done and recieved will be deleted' },
    { number: 4, type: 'PII', description: 'KYC details such as GST information, video kyc will be deleted' },
    { number: 5, type: 'Device Info.', description: 'Sim Information and Network Details will be deleted' },
  ];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'hidden' }}>
      
      <Typography
        variant={"label"}
        size={"x-large"}
        color="black"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={{ marginTop: '1%', marginBottom: '1%' }}
      >
        Do you want to delete your data
      </Typography>
      <Typography
        variant={"label"}
        size={"medium"}
        color="black"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={{ marginTop: '1%', marginBottom: '1%' }}
      >
        Following data will be deleted
      </Typography>

      <TableContainer sx={{ marginBottom: '20px', width: '80%', marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Map over the data array to generate table rows */}
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.number}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography
        variant={"label"}
        size={"medium"}
        color="black"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={{ marginTop: '5%', marginBottom: '3%' }}
      >
        Note: You can prevent the data deletion within 30 days by logging through SuperPe App
      </Typography>

      <Button
      text={buttonPressed ? 'Data Deleted' : 'Continue'}
      size={"medium"}
      onClick={handleButtonClick}
      bgColor={buttonPressed ? 'red' : 'black'}
      color={buttonPressed ? 'black' : 'white'}
      shape="rect"
      width="80%"
      endIcon={buttonPressed ? '':<img src={whiteArrowIcon} height="18px" />}
      customStyles={{ position: 'absolute', bottom: '6%' }}
    />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
